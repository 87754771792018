/**
 * DO NOT IMPORT ANY OTHER FILES FROM @designer-core TO KEEP THE BUNDLE SLIM
 */
// import 'promise-polyfill/src/polyfill';
// import { polyfill as PromisePolifill } from 'es6-promise'
// PromisePolifill();

import "ie-array-find-polyfill"
import { Config } from '@wqd/designer-core-lib/dist/runtime/Config'
import * as Logger from "@wqd/designer-core-lib/dist/logger"
import * as DefinePage from '@wqd/designer-core-lib/dist/define/Page'
import * as Events from './events'
(window as any).axios.interceptors.request.use(function (config: any) {
    // Do something before request is sent
    console.log(config);
    let token = localStorage.getItem('wqd-member-token');
    if (token && token.length > 0) {
        config.headers['wqd-member-token'] = token;
    }
    return config;
});


declare global {
    interface Window {
        bridge: {
            (data: {
                i?: string,
                type?: string,
                name: string,
                id?: string
            }): boolean
        }
    }
}
window.bridge = function (data) {
    data.i = 'DESIGNER_BRIDGE'
    if (window.parent && window.parent !== window) {
        data.i = data.i || 'DESIGNER_BRIDGE';
        data.type = data.type || 'ROUTER';
        window.parent.postMessage(JSON.stringify(data), '*');
        console.log('trigger bridge', data)
        return true;
    }
    else {
        let url = '/mobile/#/bridge/designer/' + encodeURIComponent(JSON.stringify(data));
        console.log('bridge url', url)
        window.open(url, '_self')
    }
};

(function () {
    // var ua = window.navigator.userAgent;
    // var msie = ua.indexOf("MSIE ");

    // if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)
    //     || !!navigator.userAgent.match(/QQBrowser/) // wechat browser in windows
    // )  // If Internet Explorer, return version number
    // {
    (function (arr) {

        arr.forEach(function (item) {
            item.prepend = item.prepend || function () {
                var argArr = Array.prototype.slice.call(arguments),
                    docFrag = document.createDocumentFragment();

                argArr.forEach(function (argItem: any) {
                    var isNode = argItem instanceof Node;
                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                });

                this.insertBefore(docFrag, this.firstChild);
            };
        });
    })([Element.prototype, Document.prototype, DocumentFragment.prototype]);
    // }
    // else  // If another browser, return 0
    // {

    // }
})()

var query = window.location.search.substring(1);
var vars = query.split('&');
(window.location as any).searchParams = {}
for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    (window.location as any).searchParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);

}



if ((window as any).NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

let LoggerIns = Logger.Logger.Logger.getWarn();
Logger.Logger.setupLogger(LoggerIns);
window.Logger = LoggerIns;

let axios = window.axios;

axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('wqd-member-token');
    let siteId = window.siteId;
    if (token && token !== '') {
        let timestamp = Date.now().toString();
        config.headers['wqd-member-token'] = token
        config.headers['wqd-timestamp'] = ''
        config.headers['wqd-sign'] = ''
    }
    if (siteId && siteId !== '') {
        config.headers['wqd-site-id'] = siteId
    }
    return config

})
// document.documentElement.style.fontSize='100px';

// if(Config.config.responsive){
//     if(Config.config.responsive.PHONE){
//         let css = document.createElement('STYLE');
//         css.innerHTML = `
//         html{
//             font-size:100px;
//         }
//         @media screen and (max-width : ${Config.config.responsive.PHONE}px){
//             html{
//                 font-size:calc(var(--page-width) / ${Config.config.responsive.PHONE} * 100);
//             }
//         }
//         `
//         document.head.appendChild(css)
//     }

//     let funPagewidth = function(){
//         let width = window.innerWidth;

//         document.documentElement.style.setProperty('--page-width',width.toString()+'px');
//     }
//     funPagewidth();
//     window.addEventListener('resize',funPagewidth);
// }
function mergeConfig(to: Config.IConfig, from: Config.IConfig) {
    if (to && from) {


        if (to.deps && to.animes && to.comps && from.deps && from.animes && from.comps) {
            to.animes.push(...from.animes);
            to.deps.push(...from.deps);
            for (let key in from.comps) {
                to.comps[key] = from.comps[key]
            }
        }
        if (to.globalStrategyLifecycle && from.globalStrategyLifecycle) {
            for (let key in from.globalStrategyLifecycle) {
                to.globalStrategyLifecycle[key] = to.globalStrategyLifecycle[key] || from.globalStrategyLifecycle[key]
            }
        }

    }
}

if (typeof Config.config === 'object') {
    if (Array.isArray(Config.config)) {
        if (Config.config.length > 0) {
            for (let i = 1; i < Config.config.length; i++) {
                mergeConfig(Config.config[0], Config.config[i]);
            }
        }

    } else {
        if (Config.config && Config.headerConfig) {

            let config = Config.config;
            let headerConfig = Config.headerConfig;
            if (config.deps && config.animes && config.comps && headerConfig.deps && headerConfig.animes && headerConfig.comps) {
                config.animes.push(...headerConfig.animes);
                config.deps.push(...headerConfig.deps);
                for (let key in headerConfig.comps) {
                    config.comps[key] = headerConfig.comps[key]
                }
            }
            if (config.globalStrategyLifecycle && headerConfig.globalStrategyLifecycle) {
                for (let key in headerConfig.globalStrategyLifecycle) {
                    config.globalStrategyLifecycle[key] = config.globalStrategyLifecycle[key] || headerConfig.globalStrategyLifecycle[key]
                }
            }

        }
    }
}






// let globalConfig = Config.globalConfig;

// if(globalConfig){
//     for(let id in globalConfig.comps){
//         let comp = globalConfig.comps[id];
//         for(let hookName in comp.hooks){
//             let hook = comp.hooks[hookName];
//             if(typeof hook === "string"){

//                 comp.hooks[hookName] = (new Function(`return ${hook}`))();

//             }
//         }
//         for(let methodName in comp.methods){
//             let method = comp.methods[methodName];
//             if(typeof method === "string"){
//                 comp.methods[methodName] = (new Function(`return ${method}`))();
//             }
//         }
//     }
// }



let config = Array.isArray(Config.config) ? Config.config[0] : (Config.config || Config.headerConfig);
if ((window as any).pageType) {
    config.pageType = (window as any).pageType
}
console.log(JSON.stringify(config.deps))
config.deps.push({
    name: '__domReady',
    type: Config.DependencyType.INTERNAL,
})



let fontFamily = config.pageFontFamily;
if (fontFamily && fontFamily.length > 0) {
    let fontEl = document.createElement('style');
    fontEl.innerHTML = `
        body{
            font-family:${fontFamily};
        }`;
    document.head.appendChild(fontEl);
}

function parseStringFunction(stringFunc: string) {
    let ret: any = null;

    try {

        ret = (new Function(`return (${stringFunc})`))();

    }
    catch (e) {
        /*
处理下面的形态
func(){}
        */
        // let str = stringFunc.trim();
        // str = str.replace(/^.*?\(/, 'function(')
        // ret = (new Function(`return (${str})`))();
        window.Logger.error('无法解析的序列化函数：', stringFunc)

    }
    // console.log(stringFunc,'\n',ret)
    return ret
}
// let z = window.jQuery(window)
// let oon = z.__proto__.on;
// z.__proto__.on=function(){
//     console.log(this)
//     // if(this[0].id==='ID_74ac5b81-bf6b-11ea-856e-3d6e9de34165'){
//     //     console.log(this[0])
//     // }
//     if(arguments[0]==='click'){
//         // console.log('z',this)
//     }
//     oon.apply(this,arguments)
// }
// let ojq = window.jQuery;
// window.jQuery = function(){
//     let obj = ojq.apply(this,arguments);
//     console.log(obj)
//     return obj;
// }
// Object.setPrototypeOf(window.jQuery,ojq.__proto__)

for (let id in config.comps) {
    let comp = config.comps[id];
    let globalStrategyEnabled = comp.globalStrategyId !== undefined && comp.globalStrategyVersion !== undefined;
    comp.hooks = comp.hooks || {};
    let hooksState = globalStrategyEnabled ? config.globalStrategyLifecycle[comp.globalStrategyId!].hooks : comp.hooks
    for (let hookName in hooksState) {

        let hook = hooksState[hookName];
        if (typeof hook === "string") {
            hook = hook.replace(/\s*function\s*\(\)\{\s+var\s+ret\s+\=\s+undefined;\s+try\{\s+ret\s+\=\s+\(/g, '').replace(/\)\.apply\(this,arguments\);\s+\}\s+catch\(e\)\{\s+console\.log\(\'STRING\sFUNCTION\sERR\'\,e\)\;\s+\}\s+return\sret\;\s+\}\s*/g, '').trim()

            // comp.hooks[hookName] = (new Function(`return ${hook}`))();
            let func = parseStringFunction(hook);

            comp.hooks[hookName] = func;


        }
    }
    comp.methods = comp.methods || {};
    let methodsState = globalStrategyEnabled ? config.globalStrategyLifecycle[comp.globalStrategyId!].methods : comp.methods
    for (let methodName in methodsState) {

        let method = methodsState[methodName];

        if (typeof method === "string") {
            method = method.replace(/\s*function\s*\(\)\{\s+var\s+ret\s+\=\s+undefined;\s+try\{\s+ret\s+\=\s+\(/g, '').replace(/\)\.apply\(this,arguments\);\s+\}\s+catch\(e\)\{\s+console\.log\(\'STRING\sFUNCTION\sERR\'\,e\)\;\s+\}\s+return\sret\;\s+\}\s*/g, '').trim()

            // comp.methods[methodName] = (new Function(`return ${method}`))();
            comp.methods[methodName] = parseStringFunction(method)
        }
    }

}



console.log(config.deps)
for (let i in config.deps) {
    let dep = config.deps[i];

    if (dep.type === Config.DependencyType.INTERNAL) {
        config.deps[i] = dep = Config.InternalDependencies[dep.name];

    }
    if (dep.css) {
        let loadCsses: string[] = [];
        if (typeof dep.css === 'string') {
            loadCsses = [dep.css]
        }
        if (Array.isArray(dep.css)) {
            loadCsses = dep.css
        }
        loadCsses.forEach(css => {
            Config.loadCss(`${Config.RootType.CDN ? Config.cdnURL : '/deps'}/${css}`);
        })

    }
}



if (config) {

    let fonts = config.fonts;
    let head = document.head;
    if ((typeof fonts === 'object') && fonts.constructor === Array) {

        fonts.forEach(font => {
            if (font.id === 'defaultid') {
                return;
            }
            let fontEl = document.createElement('link');
            fontEl.href = font.url;
            fontEl.rel = 'stylesheet';
            // fontEl.type = 'text/css';
            // fontEl.innerText = `@font-face{font-family:${font.name};src:${font.files.map((f ) => { return `url('${f.url}')` }).join(',')};`;
            // fontEl.setAttribute('app-id',font.id);
            head.appendChild(fontEl);
        })
    }

    let fontEl = document.createElement('style');
    fontEl.innerHTML = `body{
            font-family:${fonts.map(font => {
        if (font.name === '默认字体') {
            return DefinePage.Page.defaultFontFamily
        }
        return font.name
    }).join(',')};
        --default-font-family:${fonts.map(font => {
        if (font.name === '默认字体') {
            return DefinePage.Page.defaultFontFamily
        }
        return font.name
    }).join(',')};
        }`
    // fontEl.type = 'text/css';
    // fontEl.innerText = `@font-face{font-family:${font.name};src:${font.files.map((f ) => { return `url('${f.url}')` }).join(',')};`;
    // fontEl.setAttribute('app-id',font.id);
    head.appendChild(fontEl);
    // document.body.style.fontFamily=fonts.map(font=>font.name).join(',')

}

// if(globalConfig){

//     let fonts = globalConfig.fonts;
//     if((typeof fonts === 'object') && fonts.constructor === Array){
//         let head = document.head;
//         fonts.forEach(font=>{
//             if(font.id==='defaultid'){
//                 return;
//             }
//             let fontEl = document.createElement('link');
//             fontEl.href=font.url;
//             fontEl.rel='stylesheet';
//             // fontEl.type = 'text/css';
//             // fontEl.innerText = `@font-face{font-family:${font.name};src:${font.files.map((f ) => { return `url('${f.url}')` }).join(',')};`;
//             // fontEl.setAttribute('app-id',font.id);
//             head.appendChild(fontEl);
//         })
//     }

//     if(globalConfig.deps){
//         for(let i in globalConfig.deps){
//             let dep = globalConfig.deps[i];
//             if (dep.type === Config.DependencyType.INTERNAL) {
//                 globalConfig.deps[i]=dep = Config.InternalDependencies[dep.name];
//                 if(config.deps.indexOf(dep)===-1){
//                     config.deps.unshift(dep);
//                 }
//             }
//             if(dep.css){
//                 Config.loadCss(`${Config.RootType.CDN?Config.cdnURL:'/deps'}/${dep.css}`);
//             }
//         }
//     }
// }

let root = document.getElementsByTagName('html')[0] as HTMLElement;
root.setAttribute('page-type', config.pageType)
root.classList.add('publish')
if (config.pageType === 'mobileStatic') {
    root.classList.add('responsive-phone')
    let resp = config.responsive;
    console.log(resp)
    root.style.setProperty('--root-page-width', resp.PC + 'px')
} else {
    let fixResponsive = function (isFirst: boolean) {
        let width = window.innerWidth;
        let resp = config.responsive;
        let pageWidth = 0
        let clsList = root.classList;

        if (resp) {
            let respType = 'pc';
            pageWidth = resp.PC;
            if (resp.PAD && width < resp.PC) {
                respType = 'pad'
                pageWidth = resp.PAD
            }
            if (resp.PHONE) {
                if (resp.PAD) {
                    if (width < resp.PAD) {
                        respType = 'phone'
                        pageWidth = resp.PHONE
                    }
                } else if (width < resp.PC) {
                    respType = 'phone'
                    pageWidth = resp.PHONE
                }
            }

            let resClass = `responsive-${respType}`
            if (isFirst === false) {
                if (!clsList.contains(resClass)) {
                    window.dispatchEvent(new Event(Events.runtime_responsive_changed))
                }
            }
            clsList.remove('responsive-pc');
            clsList.remove('responsive-pad');
            clsList.remove('responsive-phone');
            root.style.setProperty('--root-page-width', pageWidth + 'px')
            clsList.add(resClass);
        }
    }
    fixResponsive(true);
    window.addEventListener('resize', () => fixResponsive(false))
}

